import React from "react";
import { Navigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";

const ProtectedRoute = ({roles, component}) => {
    const token = sessionStorage.getItem('token');

    if(!token) return <Navigate to="/login" />

    const {userConfig: user, exp} = jwt_decode(token);

    const currentTime = Math.floor(Date.now() / 1000); 

    if(exp && exp < currentTime) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        return <Navigate to="/login" />
    }

    return user && roles.includes(user.role) ? component: <Navigate to="/login" />
}

export default ProtectedRoute;