const isNullOrUndefinedOrEmpty = (value) => value === undefined || value === "";

const validateChain = (...fields) => {
    for(let field of fields) {
        if(isNullOrUndefinedOrEmpty(field)) return false
    }
    return true;
}

export {
    isNullOrUndefinedOrEmpty,
    validateChain
}