import React, { useState } from "react";
import MagkahiosaLogo from "./../../../images/MagkahiOSA-logo.jpg";
import Input from "../../../components/input/input";
import { Alert } from "@mui/material";
import { ForgotPasswordMutation } from "../../../lib/postsRequests";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const [alert, setAlert] = useState({
    display: false,
    type: "warning",
    text: "",
  });

  const { mutate } = ForgotPasswordMutation();

  const forgotPassword = async () => {
    if (!email) return;
    mutate(
      { email },
      {
        onSuccess: (data) => {
          setAlert({
            display: true,
            type: "success",
            text: data.message,
          });
          setTimeout(() => navigate('/password-reset'), 2000);
        },
        onError: (data) => {
          setAlert({
            display: true,
            type: "error",
            text: data.message,
          });

          setTimeout(() => {
            setAlert({
              display: false,
              type: "",
              text: "",
            });
          }, 2000);
        },
      }
    );
  };

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white">
      <div className="flex flex-wrap justify-center">
        <form
          onSubmit={(e) => {
            setAlert({
              display: true,
              type: "info",
              text: "Processing...",
            });
            e.preventDefault();
          }}
          className="flex w-[90%]  flex-col items-center justify-center gap-4 m-5"
        >
          <img
            src={MagkahiosaLogo}
            className="h-[60px] object-contain mb-10"
            alt="Magkahiosa Logo"
          />
          <p className="text-2xl">RESET YOUR PASSWORD</p>

          <div className={alert.display ? "block animate-pulse" : "hidden"}>
            <Alert severity={alert.type} variant="standard">
              {alert.text}
            </Alert>
          </div>
          <Input
            label="Email"
            placeholder="Enter Email for Password Recovery"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          />
          <button
            className={`min-w-60 bg-[#1D4ED8] hover:bg-[#1139a8] flex items-center justify-center gap-2 text-white mt-0 py-2 px-4 rounded-[4px] font-normal ${
              alert.display
                ? "active:bg-[#0338c8] pointer-events-none"
                : "active:bg-[#0338c8]"
            }`}
            onClick={forgotPassword}
          >
            <span
              className={
                alert.display
                  ? "material-symbols-outlined animate-spin"
                  : "hidden material-symbols-outlined"
              }
            >
              refresh
            </span>
            Send Email
          </button>
          <p
            className="px-3 py-2 text-sm font-normal rounded-md cursor-pointer hover:bg-gray"
            onClick={() => {
              navigate('/login');
            }}
          >
            Back
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
