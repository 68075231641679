import axios from "axios";

const localURL = () => {
    const baseURL = "http://127.0.0.1:3000/"
    const sarfURL = "http://127.0.0.1:3100/"
    const axiosConfig = axios.create({
        baseURL: "http://127.0.0.1:3000/",
        headers: {
            get: {
                Accept: "application/json",
            },
            post: {
                "Content-Type": "application/json",
            },
        }
    });

    return { baseURL, sarfURL, axiosConfig }
}

const deployedURL = () => {
    const sarfURL = "https://magkahiosa.06222001.xyz/api/" 
    const baseURL = "https://magkahiosa.06222001.xyz/sarf/"

    const axiosConfig = axios.create({
        baseURL: "https://magkahiosa.06222001.xyz/api/",
        headers: {
            get: {
                Accept: "application/json",
            },
            post: {
                "Content-Type": "application/json",
            },
        }
    
    });

    return { baseURL, sarfURL, axiosConfig }
}

export {
    localURL,
    deployedURL
}
