import { configureStore } from '@reduxjs/toolkit'

import navReducer from './navCache'


import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { combineReducers } from '@reduxjs/toolkit'

const persistConfig ={
  key:"root",
  version:1,
  storage,
}
const reducer = combineReducers({
 
    navbar:navReducer,
   

})





const persistedReducer =  persistReducer(persistConfig,reducer)

export default configureStore({
  reducer: persistedReducer
})

