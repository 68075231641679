import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deployedURL, localURL } from "./axiosConfig";

const {axiosConfig: axios, baseURL, sarfURL} = deployedURL();

// AUTH

const forgotPasswordAPI = async (data) => {
    const response = await axios.post(
        `auth/forgot-password`,
        {
            ...data
        },
    )

    return response.data;
}

export const ForgotPasswordMutation = () => {
    return useMutation((value) => forgotPasswordAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
        }
    });
}

const resetPasswordAPI = async (data) => {
    const response = await axios.post(
        `auth/reset-password`,
        {
            ...data
        },
    )
    return response.data;
}

export const ResetPassword = () => {
    return useMutation((value) => resetPasswordAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
        }
    });
}


const selectOrganizationAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `auth/selectOrganization`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const SelectOrganization = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => selectOrganizationAPI(value), {
        onSuccess: () => {
            queryClient.invalidateQueries("documents");
            queryClient.invalidateQueries("drafts");
        }
    });
}


// USERS

const createUserAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/`,
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const CreateUser = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => createUserAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries(["officers", "officeUsers"]);
        }
    })
}

const addExistingUserAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/addOfficer`,
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const AddExistingUserMutation = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => addExistingUserAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries("officers");
        }
    })
}

const updateUserAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/update`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        }
    )
    return response.data;
}

export const UpdateUser = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => updateUserAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries(["signatories", "officeUsers"]);
        }
    })
}

// ORGOFFICER

const addOrgMemberAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `orgOfficer/`,
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const AddOrgMember = () => {
    return useMutation((value) => addOrgMemberAPI(value));
}

const updateOrgMemberAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `orgOfficer/update`,
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const UpdateOrgMember = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => updateOrgMemberAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries("orgOfficers");
        }
    });
}


const deleteOrgMemberAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `orgOfficer/delete`,
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const DeleteOrgMember = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => deleteOrgMemberAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries("orgOfficers");
        }
    });
}

// OFFICES

const createOfficeAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `office/`,
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const CreateOffice = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => createOfficeAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries("offices");
        }
    })
}

const updateOfficeAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `office/update`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const UpdateOffice = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => updateOfficeAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries("offices");
        }
    });
}

const deleteOfficeAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `office/delete`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const DeleteOffice = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => deleteOfficeAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries("offices");
        }
    });
}

const softDeleteUserAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/softDelete`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const SoftDeleteUser = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => softDeleteUserAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries(["signatories", "officeUsers"]);
        }
    });
}

const setCosignatoriesAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/setCosignatories`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data
}

export const SetCosignatories = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => setCosignatoriesAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message.message);
            queryClient.invalidateQueries(["officeUsers"]);
        }
    });
}

const restoreUserAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/restore`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    )
    return response.data;
}

export const RestoreUser = () => {
    const queryClient = useQueryClient();
    return useMutation((value) => restoreUserAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
            queryClient.invalidateQueries(["signatories", "officeUsers", "inactiveUsers", "inactiveOrgs"]);
        }
    });
}