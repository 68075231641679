import React, { useEffect, useState } from "react";
import MagkahiosaLogo from "./../../../images/MagkahiOSA-logo.jpg";
import Input from "../../../components/input/input";
import { Form, useNavigate } from "react-router-dom";
import { ResetPassword } from "../../../lib/postsRequests";
import Alert from "@mui/material/Alert";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);
  const [data, setData] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });

  const [alert, setAlert] = useState({
    display: false,
    type: "warning",
    text: "",
  });

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const { mutate } = ResetPassword();

  const resetPassword = () => {
    if(!valid) return

    setAlert({
      display: true,
      type: "info",
      text: "Processing, Please wait...",
    });
    
    mutate(data, {
      onSuccess: (data) => {
        setAlert({
          display: true,
          type: "success",
          text: data.message,
        });

        navigate('/login')
      },
      onError: (data) => {
        setAlert({
          display: true,
          type: "error",
          text: data.message,
        });

        setTimeout(() => {
          setAlert({
            display: false,
            type: "error",
            text: "",
          });
        }, 3000);
      }
    })
  }

  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  useEffect(() => {
    if (!regex.test(data.password)) {
      setError(
        "Password must be at least 8 characters long"
      );
      setValid(false);
    } else {
      setError("");
    }

    if (data.password !== data.confirmPassword) {
      setError2("Password did not matched");
      setValid(false);
      if(!regex.test(data.confirmPassword))
        setError2("Password must be at least 8 characters long");
      
    } else {
      setError2("");
      setValid(true);
    }

    if (data.password == "") {
      setError("");
    }
  }, [data]);
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white">
      <div className="flex flex-wrap justify-center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex w-[100%] flex-col items-center justify-center gap-4 m-5"
        >
          <img
            src={MagkahiosaLogo}
            className="h-[60px] object-contain mb-10"
          ></img>
          <p className="text-2xl">RESET PASSWORD</p>
            <Input 
                label="Enter code sent to your email."
                value={data.token}
                onChange={(event) => {
                  setData({ ...data, token: event.target.value });
                }}
            />
            <Input
                label="Enter new password."
                placeholder="Enter new Password"
                type="password"
                value={data.password}
                warning={error}
                onChange={(event) => {
                  setData({ ...data, password: event.target.value });
                }}
              />
              <Input
                label="Confirm new password."
                placeholder="Confirm New Password"
                type="password"
                warning={error2}
                value={data.confirmPassword}
                onChange={(event) => {
                  setData({ ...data, confirmPassword: event.target.value });
                }}
              />
          <button className="w-100 bg-[#1D4ED8] hover:bg-[#1139a8]  text-white mt-0 py-2 px-4 rounded-[4px] font-normal active:bg-[#0338c8]"
          onClick={resetPassword}>
            Confirm Password Reset
          </button>
          <div className={alert.display ? "block animate-pulse" : "hidden"}>
              <Alert severity={alert.type} variant="standard">
                {" "}
                {alert.text}
              </Alert>
            </div>
          <p
            className="px-3 py-2 text-sm font-normal rounded-md cursor-pointer hover:bg-gray"
            onClick={() => {
              navigate('/login')
            }}
          >
            Back
          </p>
        </form>
      </div>
    </div>
  );
};

export default PasswordReset;
