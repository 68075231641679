import React, { useEffect, useState, useMemo } from 'react';

import useDebounce from '../../../components/debounce/debounce';
import Input from '../../../components/input/input';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isNullOrUndefinedOrEmpty, validateChain } from '../../../utils/valueChecking.utils';
import { AddOrgMember, DeleteOrgMember, UpdateOrgMember } from '../../../lib/postsRequests';
import { GetOrgMembers } from '../../../lib/main_connection';
import OrgMemberTableData from '../../../components/table-data/orgMemberTableData';
import { Pagination } from '@mui/material';

const AddMember = () => {
  const [search, setSearch] = useState('');
  const query = useDebounce(search, 500);

  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);

  const [sort, setSort] = useState('name')

  const [orgMembers, setOrgMembers] = useState([]);
  
   // Pagination hooks
   const [currentPage, setCurrentPage] = useState(0);
   const dataPerPage = 5;
   const firstItemIndex = currentPage * dataPerPage;
   const lastItemIndex = Math.min((currentPage + 1) * dataPerPage, orgMembers.length);
   const [firstPageRows, setFirstPageRows] = useState(orgMembers.slice(firstItemIndex, lastItemIndex));;

   useEffect(() => {
    const newFirstPageRows = orgMembers.slice(firstItemIndex, lastItemIndex);
    setFirstPageRows(newFirstPageRows);
  }, [currentPage, orgMembers]);

  useEffect(() => {
    setSearch(search);
    sessionStorage.setItem('page', 0);
  }, [query]);

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [officerId, setOfficerId] = useState('');

  const { mutate } = AddOrgMember();
  const { mutate: updateOrgMember} = UpdateOrgMember();
  const { mutate: deleteOrgMember} = DeleteOrgMember();


  const { data, refetch } = GetOrgMembers();

  useEffect(() => {
    if(!data) refetch();
    if(data && data.officers) {
      setOrgMembers(data.officers);
    }
  }, [data, refetch])

  const addOrgMember = () => {
    if (validateChain(name)) {
      setIsLoading(true);
      mutate(
        { name },
        {
          onSuccess: () => {
            setIsLoading(false);
            setName('');
            refetch();
            toast.success('Org Member Added', {
              autoClose: 2000,
            });
          },
          onError: (data) => {
            setIsLoading(false);
            toast.error(data.message, {
              autoClose: 5000,
            });
          },
        }
      );
    }
  };

  const updateMember = () => {
    if(validateChain(name, officerId)) {
      setIsLoading(true);
      const payload = {
        officerId,
        name
      }

      updateOrgMember(payload, {
        onSuccess: () => {
          setIsLoading(false);
          setName('');
          setOfficerId('');
          refetch();
          toast.success('Org Member Updated', {
            autoClose: 2000,
          });
        },
        onError: (data) => {
          setIsLoading(false);
          toast.error(data.message, {
            autoClose: 5000,
          });
        }
      })
    }
  }

  const deleteMember = (id) => {
    if(validateChain(id)) {
      setIsLoading(true);
      deleteOrgMember({officerId: id}, {
        onSuccess: () => {
          setIsLoading(false);
          refetch();
          toast.success('Org Member Deleted', {
            autoClose: 2000,
          });
        },
        onError: (data) => {
          setIsLoading(false);
          toast.error(data.message, {
            autoClose: 5000,
          });
        }
      })
    }
  }

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col gap-10 w-[95%] min-h-[100px] py-10 mx-5 ">
        <div
          className={
            creating
              ? 'border-dashed border-blue border-opacity-40 border-[1px] rounded-md w-full min-h-[260px] px-6 py-4 pt-7 flex-col flex bg-white shadow-md'
              : 'hidden'
          }
        >
          <div className="flex justify-between">
            <div className="flex flex-col leading-4">
              <h1 className="self-start text-xl font-bold ">
                Organization Member
              </h1>
              <p className="text-xs font-medium">{editing ? "Update Org Member": "Add Member to Organization"}</p>
            </div>
            <button
                className=" border-dashed border-blue border-opacity-40 border-[1px] rounded-sm  h-6 px-5 py-1 text-xs bg-[#FF9292] hover:bg-opacity-90"
                onClick={() => {
                  setCreating(!creating);
                  setOfficerId('');
                  setName('')
                  setEditing(false);
                }}
              >
                Cancel
              </button>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="flex flex-col  pb-8  gap-3 p-5 mt-3 mb-5 border-[1px] border-opacity-10 rounded-lg border-blue "
          >
            <div className="flex items-end gap-6"></div>

            <div className="flex flex-col gap-3">
              <Input
                label="Full Name"
                placeholder="Add Member's Full Name"
                width="40%"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-5 mt-4">
              <button
                className="flex items-center justify-center w-full py-2 text-sm font-normal text-center text-white rounded-sm text-opacity-90 text-md bg-blue hover:bg-opacity-90"
                disabled={isLoading}
                onClick={() => {
                  editing ? updateMember() : addOrgMember();
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className={creating ? "hidden" : "flex justify-center"}>
          <div className="w-full p-7 border-dashed border-blue border-opacity-40 border-[1px] rounded-md pb-5 bg-white">
            <div className="flex justify-between ">
              <div className="flex flex-col leading-4">
                <p className="font-semibold text-md">Organization Members</p>
                <p className="text-xs font-medium py-2">
                  Student Officers
                </p>
              </div>

              <div className="flex items-center gap-6 leading-4 slg:gap-2">
                <div className="flex text-sm pt-1 pl-1 font-medium slg:block">
                  <p>Search by :</p>
                  <div className="underline border-none outline-none ml-2">Name</div>
                </div>

                <button
                  className="flex items-center gap-1 px-4 py-1 text-xs rounded-sm bg-yellow active:bg-opacity-80 text-blue"
                  onClick={() => {
                    setCreating(!creating)
                    setOfficerId('')
                    setName('')
                    setEditing(false)
                  }}
                >
                  <span className="material-symbols-outlined">add</span>
                  Add Org Member
                </button>

                <div className="relative flex items-center">
                  <span className="absolute ml-2 material-symbols-outlined">
                    search
                  </span>
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className=" pl-8 w-full outline-none focus:border-[#331dd8] border-[1px] bg-gray border-[#00000050] text-sm  py-[10px] rounded-[4px] font-[500] "
                  />
                </div>
              </div>
            </div>

            <table className="w-full mt-5 border-separate border-spacing-0">
              <thead>
                <tr className="text-xs   h-[40px] rounded-lg w-full  border-[2px] text-left bg-gray ">
                  <th className="pl-5 rounded-l-md">Member's Name</th>
                  <th className="pl-3 rounded-r-md"></th>
                </tr>
                <hr className="mb-3 border-none " />
              </thead>
              <tbody>
                {firstPageRows
                  .filter((e) => {
                    const sortValue = e[sort];
                    return (
                      sortValue &&
                      (sortValue
                        .toLowerCase()
                        .startsWith(query.toLowerCase()) ||
                        sortValue.toLowerCase().includes(query.toLowerCase()))
                    );
                  })
                  .map((e, key) => {
                    return (
                      <OrgMemberTableData
                        key={key}
                        name={e.name}
                        edit={() => {
                          setCreating(!creating);
                          setOfficerId(e._id);
                          setEditing(true);
                          setName(e.name);
                        }}
                        delete={() => {
                          deleteMember(e._id)
                        }}
                      />
                    );
                  })}
              </tbody>
            </table>
            <br />
            <div className="flex flex-col items-center justify-center gap-4 mt-4">
              <Pagination
                count={Math.ceil(orgMembers.length / dataPerPage)}
                page={currentPage + 1}
                onChange={(event, value) => {
                  setCurrentPage(value - 1);
                }}
                variant="outlined"
                shape="rounded"
                color="standard"
              />

              <p className="text-sm">
                Showing the first <b>{firstPageRows.length} results</b> of{" "}
                {orgMembers.length} rows
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AddMember;
