import { deployedURL, localURL } from "./axiosConfig";
import { useMutation, useQuery } from "@tanstack/react-query";

const {axiosConfig: axios, baseURL, sarfURL} = deployedURL();
// AUTH

export const loginAPI = async(data) => {
    const response = await axios.post(
        `auth/login`, 
        {
            ...data
        },
    );
    return response;
}

const updatePasswordAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `auth/update-password`, 
        {
            ...data
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const UpdatePassword = () => {
    return useMutation((value) => updatePasswordAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
        }
    });
}

const uploadHeaderAPI = async (data) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.post(
        `users/header`,
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data"
            }
        }
    )
    return response.data;
}

export const UploadHeader = () => {
    return useMutation((value) => uploadHeaderAPI(value), {
        onSuccess: (data) => {
            if(data.status == 'error') throw new Error(data.message);
        }
    })
}

// OFFICES
export const getOfficesAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `office/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetOffices = () => {
    return useQuery(["offices"], () => getOfficesAPI());
}


// USERS

const getUserAPI = async (_id) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/`, {
            params: {
                _id: _id
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );

    return response.data;
}


export const GetUser = (value) => {
    return useQuery(["user"], () => getUserAPI(value), {enabled: false})
}

export const getOrganizationAPI = async (_id) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/organization`, {
            params: {
                ..._id
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetOrganization = (value) => {
    return useQuery(["organization"], () => getOrganizationAPI(value), {enabled: false})
}

export const getSignatoryUsersAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/signatories/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetSignatoryUsers = () => {
    return useQuery(["signatories"], () => getSignatoryUsersAPI());
}

export const GetSignatoryUsersRefetch = () => {
    return useQuery(["signatories"], () => getSignatoryUsersAPI(), {enabled: false});
}


export const getOfficeUsersAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/officeUsers/`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetOrgs = () => {
    return useQuery(["officeUsers"], () => getOfficeUsersAPI());
}

export const GetOrgsRefetch = () => {
    return useQuery(["officeUsers"], () => getOfficeUsersAPI(), {enabled: false});
}


export const getOrgOfficersAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `orgOfficer/officersUsers`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetOrgOfficers = () => {
    return useQuery(["orgOfficers"], () => getOrgOfficersAPI());
}

// ORGANIZATION

const getAllOfficersInOrgAPI = async () =>{
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/organization/officers`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetAllOfficersInOrg = () => {
    return useQuery(["officers"], () => getAllOfficersInOrgAPI());
}

const getUsersAndOfficersAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/usersAndOfficers`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetUsersAndOfficers = () => {
    return useQuery(["usersAndOfficers"], () => getUsersAndOfficersAPI());
}


const getOrgMembersAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `orgOfficer`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetOrgMembers = () => {
    return useQuery(["orgOfficers"], () => getOrgMembersAPI());
}

const getConflictOrganizationsAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/conflictOrganizations`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );

    return response.data;
}

export const GetConflictOrganizations = () => {
    return useQuery(["conflictOrganizations"], () => getConflictOrganizationsAPI());
}

export const GetConflictOrganizationsRefetch = () => {
    return useQuery(["conflictOrganizations"], () => getConflictOrganizationsAPI(), {enabled: false});
}

const getInactiveUsersAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/inactiveUsers`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetInactiveUsers = () => {
    return useQuery(["inactiveUsers"], () => getInactiveUsersAPI());
}

export const GetInactiveUsersRefetch = () => {
    return useQuery(["inactiveUsers"], () => getInactiveUsersAPI(), {enabled: false});
}

const getInactiveOrgsAPI = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/inactiveOrgs`, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetInactiveOrgs = () => {
    return useQuery(["inactiveOrgs"], () => getInactiveOrgsAPI());
}

export const GetInactiveOrgsRefetch = () => {
    return useQuery(["inactiveOrgs"], () => getInactiveOrgsAPI(), {enabled: false});
}

export const getOrgWithSignatoriesAPI = async (organizationId) => {
    const token = sessionStorage.getItem('token');
    const response = await axios.get(
        `users/orgWithSignatories`, 
        {
            params: {
                organizationId: organizationId
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
    return response.data;
}

export const GetOrgWithSignatories = (value) => {
    return useQuery(["orgWithSignatories"], () => getOrgWithSignatoriesAPI(value));
}