import MagkahiOSALogo from '../../images/MagkahiOSA-Logo-1.png';

const OrgMemberTableData = (props) => {
  return (
    <>
      <tr className=" text-xs h-[50px] bg-gray rounded-3xl hover:bg-[#e6e6e673] transition-colors py-2">
        <td className="max-w-[60%] pl-3 center rounded-l-md">
          <div className="flex items-center gap-3">
            <img
              src={MagkahiOSALogo}
              className="h-[35px] w-[35px] rounded-full"
            />
            <div className="leading-3 ">
              <h1 className="text-xs font-semibold ">{props.name}</h1>
            </div>
          </div>
        </td>
        <td className="max-w-[40%] px-5 rounded-r-md">
          <div className="flex gap-5 justify-end">
            <div
              className="flex items-center justify-center gap-1 cursor-pointer hover:animate-pulse text-yellow"
              onClick={props.edit}
            >
              <span className="material-symbols-outlined">edit</span>
              <p>Edit</p>
            </div>

            <div
              className="flex items-center justify-center gap-1 text-[red] hover:animate-pulse cursor-pointer"
              onDoubleClick={props.delete}
            >
              <span className="material-symbols-outlined ">delete</span>
              <p>Delete</p>
            </div>
          </div>
        </td>
      </tr>
      <div className="mb-3 border-none"/>
    </>
  );
};

export default OrgMemberTableData;