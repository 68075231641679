
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense, lazy } from 'react';
import './App.css'

import Email from './screens/authentication/email-message/email';
import ForgotPassword from './screens/authentication/forgotpassword/forgotpass';

import { AuthProvider } from 'react-auth-kit'
import { RequireAuth } from 'react-auth-kit'

import PasswordReset from './screens/authentication/forgotpassword/passwordreset';
import Loader from './components/loader/loader';
import AddMember from "./screens/organization/officerManagement/addMember";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import ProtectedRoute from "./lib/protectedRoutes";


//LOGIN ROUTES
const LoginPage = lazy(() =>
wait(2000).then(() => import("./screens/authentication/login/login"))
);

//ORGANIZATION UI ROUTES
const Organization = lazy(() => 
wait(1000).then(() => import("./screens/organization/organization")));

const OrganizationDashboardPage = lazy(() => 
wait(1000).then(() => import("./screens/organization/dashboard/organizationDashboard")));

const OrganizationApprovalPage = lazy(() => 
wait(1000).then(() => import("./screens/organization/approval/approvalPage")));

const OfficerManagementPage = lazy(() => 
wait(1000).then(() => import("./screens/organization/officerManagement/officers")));

const ExistingUser = lazy(() => 
wait(1000).then(() => import("./screens/organization/officerManagement/existingUser")));

const OrganizationProfile = lazy(() => 
wait(1000).then(() => import("./screens/profile/organizationProfile")));

//SECRETARY UI ROUTES
const Secretary = lazy(() => 
wait(1000).then(() => import("./screens/secretary/secretary")));

const SecretaryDashboardPage = lazy(() => 
wait(1000).then(() => import("./screens/secretary/dashboard/secretaryDashboard")));

const OsaSecretary = lazy(() => 
wait(1000).then(() => import("./screens/osa-secretary/osa-secretary")));

const OsaSecretaryDashboardPage = lazy(() => 
wait(1000).then(() => import("./screens/osa-secretary/dashboard/secretaryDashboard")));

const OsaSecretaryUserManagementPage = lazy(() => 
wait(1000).then(() => import("./screens/osa-secretary/userManagement/userManagement")));

const OsaSecretaryUpdateUserPage = lazy(() =>
wait(1000).then(() => import('./screens/osa-secretary/userManagement/updateUser')));

//SIGNATORY UI ROUTES
const Signatory = lazy(() => 
wait(1000).then(() => import("./screens/signatory/signatory")));

const SignatoryDashboardPage = lazy(() => 
wait(1000).then(() => import("./screens/signatory/dashboard/sigDashboard")));



//USER UI ROUTES
const User = lazy(() =>
wait(1000).then(() => import("./screens/user/user"))
);
const UserDashboardPage = lazy(() =>
wait(500).then(() => import("./screens/user/dashboard/userDashboard"))
);
const UserSarfCreate = lazy(() =>
wait(500).then(() => import("./screens/user/sarfManagement/createsarf/createSarf"))
);
const UserSarfEvaluation = lazy(() =>
wait(500).then(() => import('./screens/user/sarfManagement/evaluation/sarfEvaluation')))
const DraftSarf = lazy(() =>
wait(500).then(() => import("./screens/user/sarfManagement/draftsarf/draftSarf"))
);
const ViewDraftSarf = lazy(() =>
wait(500).then(() => import('./screens/user/sarfManagement/draftsarf/viewDraftSarf')))



const UserProfile = lazy(() =>
wait(500).then(() => import("./screens/profile/profile"))
);
const EditUserProfile = lazy(() =>
wait(500).then(() => import("./screens/profile/editProfile"))
);
const CalendarSchedule = lazy(() =>
wait(500).then(() => import("./screens/user/calendar/calendar"))
);



//ADMIN ROUTES
const Admin = lazy(() =>
wait(1000).then(() => import("./screens/admin/admin"))
);
const AdminDashboard = lazy(() =>
  wait(500).then(() =>import("./screens/admin/dashboard/adminDashboard"))
);
const AdminSarfStatusPage = lazy(() =>
wait(500).then(() => import("./screens/admin/sarfTracking/sarfStatus/sarfStatus"))
);
const AdminSarfEvaluationPage = lazy(() =>
wait(500).then(() =>import("./screens/admin/sarfTracking/evaluation/sarfEvaluation"))
);
const AdminUserManagementPage = lazy(() =>
wait(500).then(() =>import("./screens/admin/userManagement/users/userManagement"))
);
const AdminUpdateUserPage = lazy(() => 
wait(500).then(() => import('./screens/admin/userManagement/users/updateUser'))
);
const AdminOrganizationManagement = lazy(() =>
wait(500).then(() =>import("./screens/admin/userManagement/organization/organization"))
);
const AdminOfficeManagementPage = lazy(() =>
wait(500).then(() =>import("./screens/admin/userManagement/offices/offices"))
);
const AdminAffectedOrganizationsPage = lazy(() =>
wait(500).then(() =>import("./screens/admin/userManagement/organization/affectedOrgs"))
);
const AdminInactiveUsersPage = lazy(() => 
wait(500).then(() => import('./screens/admin/userManagement/trashed/inactiveUsers'))
);
const AdminInactiveOrgsPage = lazy(() =>
wait(500).then(() => import('./screens/admin/userManagement/trashed/inactiveOrganizations'))
);

//VCSAS ROUTES
const Vcsas = lazy(() =>
wait(1000).then(() => import("./screens/vcsas/vcsas"))
);
const VcsasDashboardPage = lazy(() =>
  wait(500).then(() =>import("./screens/vcsas/dashboard/vcsasDashboard"))
);
const VcsasSarfStatus = lazy(() =>
wait(500).then(() => import("./screens/vcsas/sarfStatus/sarfStatus"))
);



const queryClient = new QueryClient({defaultOptions: {queries: {staleTime: 1000 * 60 * 5}}});


function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
      <AuthProvider authType={'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}>
        <BrowserRouter>

          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />

            <Route path="/login" element={
                
                <Suspense fallback={<Loader/>}>
                    <LoginPage />
                </Suspense>
                } />

       
            <Route path="*" element={<Navigate replace to="/login" />} />
       
          <Route path="/email" element={<Email/>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset" element={<PasswordReset />} />

          <Route path="/edit-profile" element={
            <Suspense fallback={<Loader/>}>
              <EditUserProfile/>
            </Suspense>
          } />

          <Route path="/profile" element={
            <Suspense fallback={<Loader/>}>
              <UserProfile/>
            </Suspense>
          } />
   
        
            <Route path="/user" element={
                <Suspense fallback={<Loader/>}>
                  <ProtectedRoute roles={["officer"]} component={<User/>}/>
                   
                </Suspense>
            }>

              <Route path="/user/dashboard" element={<UserDashboardPage/>} />
              <Route path="/user/create-sarf" element={<UserSarfCreate/>} />
              <Route path="/user/draft-sarf" element={<DraftSarf/>} />
              <Route path="/user/view-draftSarf" element={<ViewDraftSarf/>} />
              <Route path="/user/evaluate-sarf" element={<UserSarfEvaluation/>} />

              <Route path="/user/calendar" element={<CalendarSchedule/>} />

            </Route>

            <Route path="/organization" element={
                <Suspense fallback={<Loader/>}>
                  <ProtectedRoute roles={["organization"]} component={<Organization/>} />
                </Suspense>
            }>

              <Route path="/organization/officers" element={<OfficerManagementPage/>} />
              <Route path="/organization/approval" element={<OrganizationApprovalPage/>} />
              <Route path="/organization/dashboard" element={<OrganizationDashboardPage/>} />
              <Route path="/organization/add-existing" element={<ExistingUser/>} />
              <Route path="/organization/add-member" element={<AddMember/>} />
              <Route path="/organization/profile" element={<OrganizationProfile/>} />


            </Route>
            
            <Route path="/admin" element={
                <Suspense fallback={<Loader/>}>
                  <ProtectedRoute roles={["admin", "osa"]} component={<Admin/>}/>
                </Suspense>
              }>

              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path="/admin/sarf-evaluation" element={<AdminSarfEvaluationPage  />} />
              <Route path="/admin/sarf-status" element={<AdminSarfStatusPage  />} />

              <Route path="/admin/manage-user" element={<AdminUserManagementPage/>} />
              <Route path="/admin/update-user" element={<AdminUpdateUserPage/>}/>
              <Route path="/admin/manage-offices" element={<AdminOfficeManagementPage />} />
              <Route path="/admin/manage-organization" element={<AdminOrganizationManagement/>} />
              <Route path="/admin/affected-organizations" element={<AdminAffectedOrganizationsPage/>} />
              <Route path="/admin/inactive-users" element={<AdminInactiveUsersPage/>} />
              <Route path="/admin/inactive-organizations" element={<AdminInactiveOrgsPage/>} />

              <Route path="/admin/calendar" element={<CalendarSchedule/>} />
              
            </Route>

            <Route path="/vcsas" element={
             
              <Suspense fallback={<Loader/>}>
                <ProtectedRoute roles={["vcsas"]} component={<Vcsas/>}/>
              </Suspense>
             
              }>

              <Route path="/vcsas/dashboard" element={<VcsasDashboardPage />} />
              <Route path="/vcsas/sarf-status" element={<VcsasSarfStatus  />} />
              
            </Route>

            <Route path="/secretary" element={
              <Suspense fallback={<Loader/>}>
                <ProtectedRoute roles={["secretary"]} component={<Secretary/>}/>
              </Suspense>
            }>
                <Route path="/secretary/dashboard" element={<SecretaryDashboardPage/>} />
            </Route>

            
              <Route path="/osa-secretary" element={
            
                <Suspense fallback={<Loader/>}>
                   <ProtectedRoute roles={["osa-secretary"]} component={<OsaSecretary/>}/>
                </Suspense>
          
            }>
              <Route path="/osa-secretary/dashboard" element={<OsaSecretaryDashboardPage/>} />
              <Route path="/osa-secretary/manage-user" element={<OsaSecretaryUserManagementPage/>} />
              <Route path="/osa-secretary/update-user" element={<OsaSecretaryUpdateUserPage/>}/>
              <Route path="/osa-secretary/manage-offices" element={<AdminOfficeManagementPage />} />
              <Route path="/osa-secretary/manage-organization" element={<AdminOrganizationManagement/>} />
              <Route path="/osa-secretary/affected-organizations" element={<AdminAffectedOrganizationsPage/>} />
              <Route path="/osa-secretary/inactive-users" element={<AdminInactiveUsersPage/>} />
              <Route path="/osa-secretary/inactive-organizations" element={<AdminInactiveOrgsPage/>} />

            </Route>

         
            <Route path="/signatory" element={
              <Suspense fallback={<Loader/>}>
                <ProtectedRoute roles={["moderator", "chairman", "principal", "dean", "chancellor"]} component={<Signatory/>}/>
              </Suspense>
            }>

              <Route path="/signatory/dashboard" element={<SignatoryDashboardPage/>} />

            </Route>

          </Routes>
        </BrowserRouter>
      </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}

function wait(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export default App;
